import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import logoImg from './../../assets/img/logo.png';
// import { HeaderAvatar } from './HeaderAvatar/HeaderAvatar';
import { HeaderMenu } from './HeaderMenu/HeaderMenu';
import { HeaderOptions } from './headerOptions/HeaderOptions';
import { useStyles } from './styles';

export const Header = ({ handleOpen }) => {
  const classes = useStyles();
  const headerRef = useRef();

  return (
    <Box ref={headerRef} className={classes.header} component='header'>
      <Box className={['container', classes.container].join(' ')}>
        <Box className={classes.wrapper}>
          <Box className={classes.logo} onMouseEnter={handleOpen}>
            <img src={logoImg} className={classes.logoImg} alt='Parta' />
            <Box className={classes.logoText} component='span'>
              Parta
            </Box>
          </Box>

          <Box className={classes.logo}>
            <HeaderMenu />
            <HeaderOptions header={headerRef} />
            {/* <HeaderAvatar /> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

Header.propTypes = {
  handleOpen: PropTypes.func,
};
