import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setCurrentParagraphId } from '../../../../redux/actions/currentParagraph';
import { NextTrackIcon, PrevTrackIcon } from '../../../icons';
import { useStyles } from './styles';

const PlayerArrowSwitch = ({ direction = 'left', paragraphInfo, onClose }) => {
  const classes = useStyles({
    direction: direction,
  });
  const dispatch = useDispatch();

  return (
    <Box className={classes.wrapper}>
      {direction === 'left' ? (
        <Box
          component='button'
          onClick={() => {
            dispatch(setCurrentParagraphId(paragraphInfo.id));
            onClose();
          }}
          className={classes.arrowBtnWrapper}
        >
          <PrevTrackIcon />
        </Box>
      ) : null}

      <Box className={classes.descriptionWrapper}>
        <Typography variant={'body2'} className={classes.descriptionChapter}>
          {paragraphInfo?.chapter}
        </Typography>

        <Typography variant={'body1'} className={classes.descriptionTitle}>
          {paragraphInfo?.name}
        </Typography>
      </Box>

      {direction === 'right' ? (
        <Box
          component='button'
          onClick={() => {
            dispatch(setCurrentParagraphId(paragraphInfo.id));
            onClose();
          }}
          className={classes.arrowBtnWrapper}
        >
          <NextTrackIcon />
        </Box>
      ) : null}
    </Box>
  );
};

PlayerArrowSwitch.propTypes = {
  direction: PropTypes.string,
  paragraphInfo: PropTypes.object,
  history: PropTypes.object,
  onClose: PropTypes.func,
};

export default withRouter(PlayerArrowSwitch);
