import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { theme } from '../../../constants';

const useStyles = makeStyles({
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  labelIcon: {
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  labelTitle: {
    fontSize: 24,
    color: theme.colors.white,
  },
});

export const AsideMenuLabel = ({ icon, title }) => {
  const classes = useStyles();

  return (
    <Box className={classes.label}>
      <Box className={classes.labelIcon}>{icon}</Box>
      <Box className={classes.labelTitle}>{title}</Box>
    </Box>
  );
};

AsideMenuLabel.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
};
