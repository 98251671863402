import PropTypes from 'prop-types';
import React from 'react';

export const CloseModalIcon = ({
  width = 16,
  height = 16,
  color = '#5C5C5C',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 15 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.66667 2.5C5.66667 2.36739 5.61399 2.24021 5.52022 2.14645C5.42645 2.05268 5.29927 2 5.16667 2C5.03406 2 4.90688 2.05268 4.81311 2.14645C4.71934 2.24021 4.66667 2.36739 4.66667 2.5V4.16667C4.66667 4.29927 4.61399 4.42645 4.52022 4.52022C4.42645 4.61399 4.29927 4.66667 4.16667 4.66667H2.5C2.36739 4.66667 2.24021 4.71934 2.14645 4.81311C2.05268 4.90688 2 5.03406 2 5.16667C2 5.29927 2.05268 5.42645 2.14645 5.52022C2.24021 5.61399 2.36739 5.66667 2.5 5.66667H4.16667C4.56449 5.66667 4.94602 5.50863 5.22733 5.22733C5.50863 4.94602 5.66667 4.56449 5.66667 4.16667V2.5Z'
        fill={color}
      />
      <path
        d='M5.66667 13.5C5.66667 13.6326 5.61399 13.7598 5.52022 13.8535C5.42645 13.9473 5.29927 14 5.16667 14C5.03406 14 4.90688 13.9473 4.81311 13.8535C4.71934 13.7598 4.66667 13.6326 4.66667 13.5V11.8333C4.66667 11.7007 4.61399 11.5735 4.52022 11.4798C4.42645 11.386 4.29927 11.3333 4.16667 11.3333H2.5C2.36739 11.3333 2.24021 11.2807 2.14645 11.1869C2.05268 11.0931 2 10.9659 2 10.8333C2 10.7007 2.05268 10.5735 2.14645 10.4798C2.24021 10.386 2.36739 10.3333 2.5 10.3333H4.16667C4.56449 10.3333 4.94602 10.4914 5.22733 10.7727C5.50863 11.054 5.66667 11.4355 5.66667 11.8333V13.5Z'
        fill={color}
      />
      <path
        d='M10.8333 2C10.7006 2 10.5735 2.05268 10.4797 2.14645C10.3859 2.24021 10.3333 2.36739 10.3333 2.5V4.16667C10.3333 4.56449 10.4913 4.94602 10.7726 5.22733C11.0539 5.50863 11.4354 5.66667 11.8333 5.66667H13.4999C13.6325 5.66667 13.7597 5.61399 13.8535 5.52022C13.9472 5.42645 13.9999 5.29927 13.9999 5.16667C13.9999 5.03406 13.9472 4.90688 13.8535 4.81311C13.7597 4.71934 13.6325 4.66667 13.4999 4.66667H11.8333C11.7006 4.66667 11.5735 4.61399 11.4797 4.52022C11.3859 4.42645 11.3333 4.29927 11.3333 4.16667V2.5C11.3333 2.36739 11.2806 2.24021 11.1868 2.14645C11.093 2.05268 10.9659 2 10.8333 2Z'
        fill={color}
      />
      <path
        d='M10.3333 13.5C10.3333 13.6326 10.3859 13.7598 10.4797 13.8535C10.5735 13.9473 10.7006 14 10.8333 14C10.9659 14 11.093 13.9473 11.1868 13.8535C11.2806 13.7598 11.3333 13.6326 11.3333 13.5V11.8333C11.3333 11.7007 11.3859 11.5735 11.4797 11.4798C11.5735 11.386 11.7006 11.3333 11.8333 11.3333H13.4999C13.6325 11.3333 13.7597 11.2807 13.8535 11.1869C13.9472 11.0931 13.9999 10.9659 13.9999 10.8333C13.9999 10.7007 13.9472 10.5735 13.8535 10.4798C13.7597 10.386 13.6325 10.3333 13.4999 10.3333H11.8333C11.4354 10.3333 11.0539 10.4914 10.7726 10.7727C10.4913 11.054 10.3333 11.4355 10.3333 11.8333V13.5Z'
        fill={color}
      />
    </svg>
  );
};

CloseModalIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};
