export const markInserts = (insertsInfo, currentTime) => {
  insertsInfo?.forEach((insert) => {
    const id = insert?.id;
    if (id && insert.type === 'AUDIO') {
      const elements = document.querySelectorAll(`.insert${id}`);
      elements?.forEach((element) => {
        if (currentTime > insert?.audioStart + 0.3) {
          element.style.backgroundColor = 'rgba(0, 51, 102, 0.2)';
        } else if (
          currentTime > insert?.audioStart &&
          currentTime < insert?.audioStart + 0.3
        ) {
          element.style.backgroundColor = 'red';
        } else if (currentTime <= insert?.audioStart) {
          element.style.backgroundColor = 'rgb(253, 253, 248)';
        }
      });
    }
  });
};
