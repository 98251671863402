import { useLazyQuery, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  GET_BOOK_BY_ID_QUERY,
  GET_BOOKMARKS_QUERY,
  GET_PARAGRAPH_BY_ID_QUERY,
  GET_SETTINGS_QUERY,
} from '../../graphql';
import { MainLayout } from '../../layouts/main/MainLayout';
import { setBookmarks } from '../../redux/actions/bookmarks';
import {
  fetchCurrentParagraph,
  setCurrentParagraphId,
} from '../../redux/actions/currentParagraph';
import { fetchSoundState, fetchThemeColor } from '../../redux/actions/settings';
import { getTotalParagraphs } from '../../utils';
import { fetchBookContent } from './../../redux/actions/bookContent';

const Book = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { currentParagraphId } = useSelector(
    (state) => state.currentParagraphReducer,
  );

  const { loading: settingsLoading } = useQuery(GET_SETTINGS_QUERY, {
    onCompleted: (response) => {
      if (response.getSettings) {
        dispatch(fetchThemeColor(response?.getSettings?.theme));
        dispatch(
          fetchSoundState(response?.getSettings?.soundless ? 'ON' : 'OFF'),
        );
      }
    },
  });

  const { loading } = useQuery(GET_BOOK_BY_ID_QUERY, {
    variables: {
      textbookId: +id,
    },
    onCompleted: (response) => {
      const bookContent = response?.getTextbookForReaderById?.chapters;
      const totalParagraphs = getTotalParagraphs(bookContent);

      dispatch(fetchBookContent(bookContent));
      if (totalParagraphs.length) {
        dispatch(setCurrentParagraphId(totalParagraphs[0].id));
      }
    },
  });

  const [paragraphQuery, { loading: paragraphLoading }] = useLazyQuery(
    GET_PARAGRAPH_BY_ID_QUERY,
    {
      variables: {
        id: +currentParagraphId,
      },
      onCompleted: (response) => {
        const paragraphInfo = response?.findParagraphById;

        dispatch(fetchCurrentParagraph(paragraphInfo));
      },
      fetchPolicy: 'network-only',
    },
  );

  const { loading: bookmarksLoading } = useQuery(GET_BOOKMARKS_QUERY, {
    onCompleted: (response) => {
      dispatch(setBookmarks(response?.getBookmarks));
    },
    variables: {
      textbookId: +id,
    },
  });

  useEffect(() => {
    if (currentParagraphId) {
      dispatch(fetchCurrentParagraph(null));
      paragraphQuery();
    }
  }, [currentParagraphId, paragraphQuery]);

  return (
    <MainLayout
      paragraphPage
      loading={
        loading || bookmarksLoading || paragraphLoading || settingsLoading
      }
    />
  );
};

export default Book;
