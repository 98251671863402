import { makeStyles } from '@material-ui/core';

import { theme } from '../../../../constants';

export const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    width: 'calc((100% - 80px) / 2)',
    justifyContent: (props) =>
      props.direction === 'left' ? 'flex-start' : 'flex-end',
  },
  arrowBtnWrapper: {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
  },
  descriptionWrapper: {
    textAlign: (props) => props.direction,
    margin: '0 17px',
    width: 'calc(100% - 60px)',
  },
  descriptionChapter: {
    fontSize: 12,
    lineHeight: '14px',
    color: 'rgba(0, 0, 0, 0.4)',
    marginBottom: 2,
  },
  descriptionTitle: {
    fontSize: 14,
    lineHeight: '16px',
    color: theme.colors.black,
  },
});
