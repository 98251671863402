import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  listItem: {
    display: 'inline-block',
    marginLeft: 40,
  },
  list: {
    listStyle: 'none',
    marginLeft: -40,
  },
  listLink: {
    color: 'white',
    fontSize: 16,
    fontWeight: 500,
    cursor: 'pointer',
  },
});
