import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

import { theme } from '../../constants';
import { ParagraphIcon, SearchIcon, SettingsIcon } from '../icons';
import { BookmarkIcon } from '../icons/BookmarkIcon';
import { AsideMenu } from './asideMenu/AsideMenu';

const useStyles = makeStyles({
  aside: {
    display: 'flex',
    backgroundColor: theme.colors.paleBlue,
    height: '100%',
    padding: '24px 12px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    zIndex: 2,
  },
  item: {
    marginBottom: 16,
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  itemButton: {
    cursor: 'pointer',
  },
});

export const Aside = ({ menuOpen, handleOpen, setMenuOpen }) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.aside}
      component='aside'
      onMouseEnter={handleOpen}
      onMouseLeave={() => setMenuOpen(false)}
    >
      <CSSTransition
        in={menuOpen}
        unmountOnExit
        timeout={200}
        classNames='slide'
      >
        <AsideMenu onClose={() => setMenuOpen(false)} />
      </CSSTransition>
      <Box onClick={handleOpen}>
        <Box className={classes.item}>
          <Box className={classes.itemButton}>
            <ParagraphIcon />
          </Box>
        </Box>
        <Box className={classes.item}>
          <Box className={classes.itemButton}>
            <SearchIcon />
          </Box>
        </Box>
        <Box className={classes.item}>
          <Box className={classes.itemButton}>
            <BookmarkIcon />
          </Box>
        </Box>
      </Box>
      <Box className={classes.item} onClick={handleOpen}>
        <Box className={classes.itemButton}>
          <SettingsIcon />
        </Box>
      </Box>
    </Box>
  );
};

Aside.propTypes = {
  menuOpen: PropTypes.bool,
  handleOpen: PropTypes.func,
  setMenuOpen: PropTypes.func,
};
