import { SettingsTypes } from '../types';

const { FETCH_THEME_COLOR, FETCH_SOUND_STATE } = SettingsTypes;

export const fetchThemeColor = (theme) => ({
  type: FETCH_THEME_COLOR,
  theme,
});

export const fetchSoundState = (soundless) => ({
  type: FETCH_SOUND_STATE,
  soundless,
});
