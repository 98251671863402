import React from 'react';

export const BookmarkIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6 6C6 5.46957 6.21071 4.96086 6.58579 4.58579C6.96086 4.21071 7.46957 4 8 4H16C16.5304 4 17.0391 4.21071 17.4142 4.58579C17.7893 4.96086 18 5.46957 18 6V19.5C18 19.5904 17.9754 19.6792 17.9289 19.7568C17.8824 19.8343 17.8157 19.8979 17.736 19.9405C17.6563 19.9832 17.5664 20.0035 17.4761 19.9992C17.3858 19.9948 17.2983 19.9661 17.223 19.916L12 17.101L6.777 19.916C6.70171 19.9661 6.61423 19.9948 6.52389 19.9992C6.43355 20.0035 6.34373 19.9832 6.264 19.9405C6.18427 19.8979 6.1176 19.8343 6.07111 19.7568C6.02462 19.6792 6.00005 19.5904 6 19.5V6Z'
        fill='white'
      />
    </svg>
  );
};
