export const getTotalParagraphs = (bookContent) => {
  let totalParagraphs = [];

  bookContent?.forEach((chapter) => {
    chapter?.paragraphs?.forEach((paragraph) => {
      totalParagraphs.push({
        ...paragraph,
      });
    });
  });

  return totalParagraphs;
};
