import { List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import { theme } from '../../../../../constants';
import { setCurrentParagraphId } from '../../../../../redux/actions/currentParagraph';
import { PlayIcon } from '../../../../icons';

const useStyles = makeStyles({
  menuItemText: {
    marginTop: 0,
    marginBottom: 0,
    '& span': {
      margin: 0,
      fontWeight: 500,
      fontSize: 13,
      marginLeft: 60,
      lineHeight: '21px',
      color: '#D6DDE0',
    },
  },
  menuItem: {
    padding: 0,
    margin: 0,
    cursor: 'pointer',
    '& svg': {
      opacity: 0,
      position: 'absolute',
    },
    '&:hover': {
      '& span': {
        color: theme.colors.white,
      },
    },
  },
  activeMenuItem: {
    '& svg': {
      opacity: 1,
    },
    '& span': {
      fontWeight: 900,
      color: theme.colors.white,
    },
  },
});

const BookContentMenuItemParagraphs = ({
  paragraphs = [],
  // history,
  onClose,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isExpandable = paragraphs && paragraphs.length > 0;
  const { currentParagraph } = useSelector(
    (state) => state.currentParagraphReducer,
  );

  const MenuItemParagraphs = isExpandable ? (
    <List component='div' disablePadding>
      {paragraphs.map((item, index) => (
        <ListItem
          key={index}
          className={`${classes.menuItem} ${
            +currentParagraph?.id === +item.id ? classes.activeMenuItem : null
          }`}
          onClick={() => {
            dispatch(setCurrentParagraphId(item.id));
            onClose();
          }}
        >
          <PlayIcon width={9} height={10} />
          <ListItemText
            primary={`${item.name}`}
            className={classes.menuItemText}
          />
        </ListItem>
      ))}
    </List>
  ) : null;

  return <>{MenuItemParagraphs}</>;
};

BookContentMenuItemParagraphs.propTypes = {
  paragraphs: PropTypes.array,
  history: PropTypes.object,
  onClose: PropTypes.func,
};

export default withRouter(BookContentMenuItemParagraphs);
