import { gql } from '@apollo/client';

export const SET_SETTINGS_MUTATION = gql`
  mutation setSetting($name: Settings!, $value: Value!) {
    setSetting(name: $name, value: $value) {
      status
    }
  }
`;

export const CREATE_BOOKMARK_MUTATION = gql`
  mutation createBookmark($paragraphId: Int!, $time: Int!) {
    createBookmark(paragraphId: $paragraphId, time: $time) {
      id
      time
      paragraph {
        id
        name
      }
    }
  }
`;
export const REMOVE_BOOKMARK_MUTATION = gql`
  mutation removeBookmark($id: Int!) {
    removeBookmark(id: $id) {
      status
    }
  }
`;

export const CREATE_MARKER_MUTATION = gql`
  mutation createMarker($start: Int!, $length: Int!, $paragraphId: Int!) {
    createMarker(start: $start, length: $length, paragraphId: $paragraphId) {
      start
    }
  }
`;

export const DELETE_MARKER_MUTATION = gql`
  mutation removeMarker($id: Int!) {
    removeMarker(id: $id) {
      status
    }
  }
`;
