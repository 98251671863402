export const getToken = () => {
  const tokenValuesAdmin = document.cookie.match(/token_admin=(.+?)(;|$)/);
  const tokenValuesCatalog = document.cookie.match(/token_catalog=(.+?)(;|$)/);
  const token = document.cookie.match(/token=(.+?)(;|$)/);

  if (tokenValuesAdmin) {
    return tokenValuesAdmin[1];
  } else if (tokenValuesCatalog) {
    return tokenValuesCatalog[1];
  } else if (token) {
    return token[1];
  } else return null;
};
