import { SearchParagrapsTypes } from '../types';

const { FETCH_SEARCH_PARAGRAPHS, SET_SEARCH_VALUE, CLEAR_SEARCH_VALUE } =
  SearchParagrapsTypes;

export const fetchSearchParagraphs = (searchParagraphs, searchText) => ({
  type: FETCH_SEARCH_PARAGRAPHS,
  searchParagraphs,
  searchText,
});

export const setSearchValue = (searchValue) => ({
  type: SET_SEARCH_VALUE,
  searchValue,
});

export const clearSearchValue = () => ({
  type: CLEAR_SEARCH_VALUE,
});
