import { gql } from '@apollo/client';

export const GET_SETTINGS_QUERY = gql`
  query {
    getSettings {
      theme
      soundless
    }
  }
`;
export const GET_BOOK_CONTENT_QUERY = gql`
  query {
    getChapters {
      name
      order
      id
      subjects {
        id
        name
        order
        chapterId
        paragraphs {
          name
          content
          id
          order
          subject {
            chapter {
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_FIND_PARAGRAPHS_QUERY = gql`
  query findParagraphs($search: String!, $textbookId: ID!) {
    findParagraphs(search: $search, textbookId: $textbookId) {
      id
      name
      content
    }
  }
`;

export const GET_BOOKMARKS_QUERY = gql`
  query getBookmarks($textbookId: ID!) {
    getBookmarks(textbookId: $textbookId) {
      id
      paragraphId
      time
      paragraph {
        name
      }
    }
  }
`;

//

export const GET_BOOK_BY_ID_QUERY = gql`
  query getTextbookForReaderById($textbookId: ID!) {
    getTextbookForReaderById(textbookId: $textbookId) {
      id
      title
      chapters {
        id
        name
        paragraphs {
          id
          name

          content
          audio
          audioDuration

          bookmark {
            id
            time
            paragraph {
              name
            }
          }
          inserts {
            id

            audioStart
            audioEnd
            contentPositionStart
            contentPositionEnd
            name
            content
            source
          }
        }
      }
    }
  }
`;

export const GET_PARAGRAPH_BY_ID_QUERY = gql`
  query findParagraphById($id: ID!) {
    findParagraphById(id: $id) {
      id
      name
      content
      audio
      audioDuration

      bookmark {
        id
        time
        paragraph {
          name
        }
      }
      inserts {
        id
        audioStart
        audioEnd
        contentPositionStart
        contentPositionEnd
        name
        content
        source
        type
        isMuted
      }
    }
  }
`;
