import { useEffect, useRef } from 'react';

/* eslint-disable jsx-a11y/media-has-caption */
const AudioInsert = ({
  insert,
  currentTime,
  mainAudioRef,
  activePlayer,
  setActivePlayer,
  insertsInfo,
  setInsertsInfo,
  setIsPlayDisable,
  isPlayerChanges,
}) => {
  const audioRef = useRef(null);

  const setCurrentDuration = () => {
    const mainAudio = mainAudioRef.current;

    !activePlayer && setActivePlayer(true);
    setTimeout(() => {
      mainAudio.play();
    }, 1000);
  };

  useEffect(() => {
    const insertIndex = insertsInfo.map((item) => item.id).indexOf(insert.id);
    let newInsertsValue = insertsInfo.map((item) => ({ ...item }));
    newInsertsValue[insertIndex].isAudioActive = true;

    const audio = audioRef.current;
    if (
      insert?.audioStart <= currentTime &&
      currentTime <= insert?.audioStart + 0.3 &&
      !isPlayerChanges
    ) {
      !insertsInfo[insertIndex].isAudioActive && audio.play();
      setInsertsInfo(newInsertsValue);
    }
  }, [currentTime]);
  return (
    <audio
      ref={audioRef}
      src={`${process.env.REACT_APP_AUDIO_FOLDER_URL}/${insert.source}`}
      onEnded={() => {
        setCurrentDuration();
        setIsPlayDisable(false);
      }}
    ></audio>
  );
};

export default AudioInsert;
