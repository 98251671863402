const addZero = (value) => {
  return value < 10 ? '0' + value : value;
};

export const getTimeOfBookmark = (secondsTime) => {
  let hour = secondsTime / 60 / 60;
  let minutes = secondsTime / 60;
  let seconds = secondsTime % 60;
  return `${addZero(Math.floor(hour))}:${addZero(
    Math.floor(minutes),
  )}:${addZero(Math.floor(seconds))}`;
};
