import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  infoWrapper: {
    width: 'calc(100% - 64px)',
    minWidth: 1024,
    padding: '48px 64px 48px 24px',
    backgroundColor: (props) => props.wrapperBackgroundColor,
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
  },
  wrapper: {
    width: '49%',
    minWidth: 600,
    padding: '14px 16px',
    backgroundColor: (props) => props.backgroundColor,
    borderRadius: 8,
    marginRight: '2%',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '146%',
    textAlign: 'center',
  },
  contentWrapper: {
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    padding: '16px 0',
  },
  content: {
    height: 'calc(100vh - 401px)',
    padding: '0px 8px',
    'scrollbar-color': '#C5D7E0 #DCDCDC',
    'scrollbar-width': 'thin',
    position: 'relative',
  },
  text: {
    fontWeight: 400,
    fontSize: 15,
    lineHeight: '21.9px',
    letterSpacing: 0.03,
    position: 'relative',
    '--lumin-background-color': (props) => props.textBackgroundColor,
  },
  infoBoxWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '49%',
    height: 'calc(100vh - 184px)',
    paddingRight: 12,
  },
  insertText: {
    cursor: 'pointer',
    color: '#0B486B',
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  markerText: {
    padding: '2px 0px',
    cursor: 'pointer',
    backgroundColor: 'rgb(242,201,76, 0.4)',
  },
  modalWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    minWidth: '1140px',
    height: '100vh',
    zIndex: 2,
  },
  modalOutside: {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    height: '100%',
    width: '100%',
  },
});
