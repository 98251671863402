import PropTypes from 'prop-types';
import React from 'react';

export const SearchIcon = ({ width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.7426 14.3429C16.7109 13.0216 17.1446 11.3834 16.9569 9.75603C16.7692 8.12869 15.974 6.63222 14.7304 5.56601C13.4867 4.4998 11.8863 3.94248 10.2494 4.00556C8.61252 4.06863 7.05978 4.74744 5.90186 5.90619C4.74394 7.06493 4.06624 8.61816 4.00434 10.2551C3.94244 11.8921 4.5009 13.492 5.568 14.7349C6.6351 15.9778 8.13214 16.7719 9.75961 16.9585C11.3871 17.145 13.025 16.7101 14.3456 15.7409H14.3446C14.3746 15.7809 14.4066 15.8189 14.4426 15.8559L18.2926 19.7059C18.4801 19.8935 18.7345 19.999 18.9997 19.9991C19.265 19.9992 19.5195 19.8939 19.7071 19.7064C19.8947 19.5189 20.0002 19.2645 20.0003 18.9992C20.0004 18.734 19.8951 18.4795 19.7076 18.2919L15.8576 14.4419C15.8218 14.4057 15.7834 14.3723 15.7426 14.3419V14.3429ZM16.0006 10.4989C16.0006 11.2212 15.8583 11.9363 15.5819 12.6036C15.3055 13.2709 14.9004 13.8772 14.3897 14.388C13.879 14.8987 13.2726 15.3038 12.6054 15.5802C11.9381 15.8566 11.2229 15.9989 10.5006 15.9989C9.77832 15.9989 9.06312 15.8566 8.39583 15.5802C7.72854 15.3038 7.12223 14.8987 6.61151 14.388C6.10078 13.8772 5.69566 13.2709 5.41926 12.6036C5.14285 11.9363 5.00059 11.2212 5.00059 10.4989C5.00059 9.04019 5.58006 7.64124 6.61151 6.60979C7.64296 5.57834 9.0419 4.99888 10.5006 4.99888C11.9593 4.99888 13.3582 5.57834 14.3897 6.60979C15.4211 7.64124 16.0006 9.04019 16.0006 10.4989Z'
        fill='white'
      />
    </svg>
  );
};

SearchIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
