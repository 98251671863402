import PropTypes from 'prop-types';
import React from 'react';

export const NextTrackIcon = ({ width = 14, height = 14 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14 0.75C14 0.551088 13.921 0.360322 13.7803 0.21967C13.6397 0.0790175 13.4489 0 13.25 0C13.0511 0 12.8603 0.0790175 12.7197 0.21967C12.579 0.360322 12.5 0.551088 12.5 0.75V13.25C12.5 13.4489 12.579 13.6397 12.7197 13.7803C12.8603 13.921 13.0511 14 13.25 14C13.4489 14 13.6397 13.921 13.7803 13.7803C13.921 13.6397 14 13.4489 14 13.25V0.75Z'
        fill='#0B486B'
      />
      <path
        d='M1.88955e-07 1.40099C5.68089e-05 1.17481 0.0614837 0.952878 0.177734 0.758854C0.293984 0.56483 0.460701 0.405984 0.660117 0.299246C0.859532 0.192508 1.08417 0.141878 1.3101 0.152752C1.53602 0.163626 1.75476 0.235597 1.943 0.360994L10.341 5.95999C10.5122 6.07415 10.6526 6.2288 10.7497 6.41022C10.8467 6.59164 10.8975 6.79423 10.8975 6.99999C10.8975 7.20576 10.8467 7.40834 10.7497 7.58977C10.6526 7.77119 10.5122 7.92584 10.341 8.03999L1.943 13.64C1.75469 13.7654 1.53586 13.8374 1.30985 13.8482C1.08384 13.8591 0.859124 13.8084 0.659676 13.7015C0.460229 13.5946 0.293526 13.4357 0.177349 13.2415C0.0611711 13.0473 -0.000124204 12.8253 1.88955e-07 12.599V1.39999V1.40099Z'
        fill='#0B486B'
      />
    </svg>
  );
};

NextTrackIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
