import { SettingsTypes } from '../types';

const { FETCH_THEME_COLOR, FETCH_SOUND_STATE } = SettingsTypes;

const initialState = {
  theme: 'WARM',
  soundless: 'OFF',
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_THEME_COLOR:
      return {
        ...state,
        theme: action.theme,
      };
    case FETCH_SOUND_STATE:
      return {
        ...state,
        soundless: action.soundless,
      };
    default:
      return state;
  }
};

export default settingsReducer;
