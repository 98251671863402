import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { theme } from '../../../constants';
import AsideMenuBookmarks from './asideMenuBookmarks/AsideMenuBookmarks';
import { AsideMenuContents } from './asideMenuContents/AsideMenuContents';

const useStyles = makeStyles({
  menu: {
    position: 'absolute',
    height: '100%',
    top: 0,
    maxWidth: 496,
    width: '35vw',
    backgroundColor: theme.colors.paleBlue,
    padding: 16,
    paddingLeft: 0,
  },
});

export const AsideMenu = ({ onClose }) => {
  const searchResult = useSelector(
    (state) => state.searchParagraphs.searchResult,
  );
  const classes = useStyles();

  return (
    <Box className={classes.menu}>
      <AsideMenuContents onClose={onClose} />
      {searchResult === null ? <AsideMenuBookmarks onClose={onClose} /> : null}
    </Box>
  );
};

AsideMenu.propTypes = {
  onClose: PropTypes.func.isRequired,
};
