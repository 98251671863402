import { CurrentParagraphTypes } from '../types';

const {
  FETCH_CURRENT_PARAGRAPH,
  REMOVE_BOOKMARK,
  CREATE_BOOKMARK,
  SET_CURRENT_PARAGRAPH_ID,
} = CurrentParagraphTypes;

const initialState = {
  currentParagraph: null,
  currentParagraphId: null,
  bookmark: null,
};

const currentParagraphReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CURRENT_PARAGRAPH:
      return {
        ...state,
        currentParagraph: action.currentParagraph,
        bookmark: action.currentParagraph?.bookmark || null,
      };
    case REMOVE_BOOKMARK:
      return {
        ...state,
        bookmark: null,
      };
    case CREATE_BOOKMARK:
      return {
        ...state,
        bookmark: {
          time: action.time,
          id: action.id,
        },
      };
    case SET_CURRENT_PARAGRAPH_ID:
      return {
        ...state,
        currentParagraphId: action.id,
      };
    default:
      return state;
  }
};

export default currentParagraphReducer;
