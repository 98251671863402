export const getInsertsFullInfo = (inserts) => {
  let result = [];

  inserts?.forEach((insert) => {
    result.push({
      type: 'insert',
      start: insert?.contentPositionStart,
      end: insert?.contentPositionEnd,
      insertType: insert.type,
      id: insert?.id,
      source: insert?.source,
    });
  });

  result.sort((a, b) => {
    return a.start - b.start;
  });

  return result;
};
