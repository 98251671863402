export const SettingsTypes = {
  FETCH_THEME_COLOR: 'FETCH_THEME_COLOR',
  FETCH_SOUND_STATE: 'FETCH_SOUND_STATE',
};

export const BookContentTypes = {
  FETCH_BOOK_CONTENT: 'FETCH_BOOK_CONTENT',
};

export const CurrentParagraphTypes = {
  FETCH_CURRENT_PARAGRAPH: 'FETCH_CURRENT_PARAGRAPH',
  REMOVE_BOOKMARK: 'REMOVE_BOOKMARK',
  CREATE_BOOKMARK: 'CREATE_BOOKMARK',
  SET_CURRENT_PARAGRAPH_ID: 'SET_CURRENT_PARAGRAPH_ID',
};

export const SearchParagrapsTypes = {
  FETCH_SEARCH_PARAGRAPHS: 'FETCH_SEARCH_PARAGRAPHS',
  SET_SEARCH_VALUE: 'SET_SEARCH_VALUE',
  CLEAR_SEARCH_VALUE: 'CLEAR_SEARCH_VALUE',
};

export const BookmarksTypes = {
  FETCH_BOOKMARKS: 'FETCH_BOOKMARKS',
};
