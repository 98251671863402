import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Aside } from '../../components/aside/Aside';
import Content from '../../components/content/Content';
import { Header } from '../../components/header/Header';
import { Preloader } from '../../components/Preloader/Preloader';
import { theme } from '../../constants';

const useStyles = makeStyles({
  content: {
    display: 'flex',
    height: 'calc(100% - 64px)',
  },
  wrapper: {
    height: '100vh',
    minHeight: 400,
  },
  infoWrapper: {
    width: 'calc(100% - 64px)',
    minWidth: 1024,
    padding: '48px 64px 48px 24px',
    backgroundColor: (props) => props.wrapperBackgroundColor,
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
  },
});

export const MainLayout = ({
  children,
  paragraphPage = false,
  isLeftNavBar = true,
  loading = false,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleOpen = () => {
    setMenuOpen(true);
  };
  const themeState = useSelector((state) => state.settings.theme);
  const { currentParagraph } = useSelector(
    (state) => state.currentParagraphReducer,
  );
  const bookContent = useSelector((state) => state.bookContent.bookContent);

  const classes = useStyles({
    wrapperBackgroundColor:
      themeState === 'COLD'
        ? theme.colors.background.secondary
        : theme.colors.background.primary,
  });

  return (
    <Box className={classes.wrapper}>
      <Header handleOpen={handleOpen} />
      <Box className={classes.content} component='main'>
        {isLeftNavBar && (
          <Aside
            menuOpen={menuOpen}
            handleOpen={handleOpen}
            setMenuOpen={setMenuOpen}
          />
        )}
        <Box className={classes.infoWrapper}>
          {paragraphPage && (
            <>
              {currentParagraph ? (
                <Content onClose={() => setMenuOpen(false)} loading={loading} />
              ) : bookContent && bookContent.length === 0 ? (
                <div>Данная книга не содержит параграфов</div>
              ) : (
                <Preloader />
              )}
            </>
          )}
          {children}
        </Box>
      </Box>
    </Box>
  );
};
