/* eslint-disable jsx-a11y/media-has-caption */
import './index.css';

import { Box, makeStyles, Typography } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux';

import { Preloader } from '../../components/Preloader/Preloader';
import { theme } from '../../constants';
import { CloseIcon, OpenModalIcon } from '../icons';

const useStyles = makeStyles({
  infoBoxWrapper: {
    backgroundColor: (props) => props.backgroundColor,
    borderRadius: 8,
    marginBottom: 24,
    overflow: 'hidden',
    position: 'relative',
    // height: (props) => `${props.wrapperHeight}px`,
    display: (props) => (props.isActive ? 'block' : 'none'),
  },
  titleWrapper: {
    width: '100%',
    padding: 16,
  },
  title: {
    fontSize: 18,
    lineHeight: '146%',
    color: theme.colors.black,
    fontWeight: 500,
  },
  contentWrapper: {
    padding: '16px 22px 32px',
  },
  img: {
    maxWidth: '100%',
    margin: '0 auto',
    marginBottom: 16,
    display: 'block',
  },
  video: {
    maxWidth: '100%',
    maxHeight: 600,
    margin: '0 auto',
    marginBottom: 16,
    display: (props) => props.visible,
  },
  description: {
    color: theme.colors.gray,
    fontSize: 14,
    lineHeight: '146%',
  },
  closeIcon: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    cursor: 'pointer',
  },
  openModalIcon: {
    position: 'absolute',
    bottom: '12px',
    right: '12px',
    cursor: 'pointer',
  },
});

export const InfoBox = ({
  title,
  content,
  type,
  source,
  audioStart,
  audioEnd,
  onClose,
  onModalOpen,
  isMuted,
  isActive,
  mainAudioRef,
  activePlayer,
  setActivePlayer,
  setIsPlayDisable,
  currentTime,
}) => {
  const themeState = useSelector((state) => state.settings.theme);
  const [isLoading, setIsLoading] = useState(true);
  const [isHide, setIsHide] = useState(false);

  const clientWidth = document.documentElement.clientWidth;

  const videoRef = useRef(null);
  const wrapperRef = useRef(null);

  const classes = useStyles({
    backgroundColor:
      themeState === 'cold'
        ? theme.colors.contentBackground.secondary
        : theme.colors.contentBackground.primary,
    visible: isLoading ? 'none' : 'block',
    wrapperHeight: (clientWidth > 1350 ? 600 : 600 - 1350 - clientWidth) + 130,
    isActive: isActive,
    isHide: isHide,
  });

  const setCurrentDuration = () => {
    const mainAudio = mainAudioRef.current;

    !activePlayer && setActivePlayer(true);
    setTimeout(() => {
      mainAudio.play();
    }, 1000);
  };

  useEffect(() => {
    type === 'VIDEO' && isActive && videoRef?.current?.play();
  }, [isActive]);

  useEffect(() => {
    if (currentTime < audioStart) {
      setIsHide(false);
    }
  }, [currentTime]);

  return (
    <Box
      className={classNames(
        classes.infoBoxWrapper,
        isActive && !isHide ? 'animation' : '',
        isHide && isMuted ? 'no-visible' : '',
        !isMuted && currentTime > audioEnd - 1 && currentTime < audioEnd
          ? 'no-visible'
          : '',
      )}
      ref={wrapperRef}
    >
      {title ? (
        <Box className={classes.titleWrapper}>
          <Typography variant='h3' className={classes.title}>
            {ReactHtmlParser(title)}
          </Typography>
        </Box>
      ) : null}

      <Box className={classes.contentWrapper}>
        {type === 'VIDEO' ? (
          <>
            {isLoading && <Preloader />}
            <video
              ref={videoRef}
              className={classes.video}
              // eslint-disable-next-line no-undef
              src={`${process.env.REACT_APP_VIDEO_FOLDER_URL}/${source}`}
              // autoPlay={true}
              controls={true}
              muted={!isMuted || !isActive}
              onLoadedData={() => {
                setIsLoading(false);
              }}
              onEnded={() => {
                if (isMuted) {
                  setCurrentDuration();
                  setIsPlayDisable(false);
                }
                setIsHide(true);
              }}
            ></video>
          </>
        ) : type === 'IMAGE' ? (
          // eslint-disable-next-line no-undef
          <img
            src={`${process.env.REACT_APP_IMG_FOLDER_URL}/${source}`}
            alt={title}
            className={classes.img}
          />
        ) : null}
        {content ? (
          <Typography variant='body1' className={classes.description}>
            {ReactHtmlParser(content)}
          </Typography>
        ) : null}
      </Box>
      {!isMuted && (
        <Box className={classes.closeIcon} onClick={onClose}>
          <CloseIcon width={10} height={10} color={'#000'} />
        </Box>
      )}

      <Box
        className={classes.openModalIcon}
        onClick={() => {
          onModalOpen();
          if (type === 'VIDEO') {
            videoRef.current.pause();
          }
        }}
      >
        <OpenModalIcon width={16} height={16} color={'#000'} />
      </Box>
    </Box>
  );
};

InfoBox.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  type: PropTypes.string,
  source: PropTypes.string,
  onClose: PropTypes.func,
  onModalOpen: PropTypes.func,
};
