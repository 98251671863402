import { SearchParagrapsTypes } from '../types';

const { FETCH_SEARCH_PARAGRAPHS, SET_SEARCH_VALUE, CLEAR_SEARCH_VALUE } =
  SearchParagrapsTypes;

const initialState = {
  searchResult: null,
  searchValue: '',
  searchText: '',
};

const searchParagraphs = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SEARCH_PARAGRAPHS:
      return {
        ...state,
        searchResult: action.searchParagraphs,
        searchText: action.searchText,
      };
    case SET_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.searchValue,
      };
    case CLEAR_SEARCH_VALUE:
      return {
        ...state,
        searchResult: null,
        searchValue: '',
        searchText: '',
      };
    default:
      return state;
  }
};

export default searchParagraphs;
