/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-undef */
import { useMutation } from '@apollo/client';
import { Box, Slider, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CREATE_BOOKMARK_MUTATION,
  REMOVE_BOOKMARK_MUTATION,
} from '../../../graphql';
import { setBookmarks } from '../../../redux/actions/bookmarks';
import {
  createBookmark,
  removeBookmark,
} from '../../../redux/actions/currentParagraph';
import { getTimeOfBookmark, getTotalParagraphs } from '../../../utils';
import {
  BookmarksActiveIcon,
  BookmarksDisabledIcon,
  PauseIcon,
  PlayIcon,
} from '../../icons';
import { Preloader } from '../../Preloader/Preloader';
import PlayerArrowSwitch from './PlayerArrowSwitch/PlayerArrowSwitch';
import { useStyles } from './styles';

const AudioSlider = withStyles({
  root: {
    color: '#0B486B',
    height: 6,
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: '#0B486B',
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  disabled: {
    '& .MuiSlider-thumb': {
      height: 16,
      width: 16,
      backgroundColor: '#C5D7E0',
      marginTop: '-4px',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 6,
    borderRadius: 3,
  },
  rail: {
    height: 6,
    borderRadius: 3,
    color: '#C5D7E0',
  },
})(Slider);

const isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === '[object SafariRemoteNotification]';
    // eslint-disable-next-line no-undef
  })(
    !window['safari'] ||
      (typeof safari !== 'undefined' && safari?.pushNotification),
  );

export const Player = ({
  luminator,
  trackValue,
  setTrackValue,
  duration,
  setDuration,
  currentTime,
  setCurrentTime,
  onClose,
  audioRef,
  insertsInfo,
  activePlayer,
  setActivePlayer,
  setInsertsInfo,
  isPlayDisable,
  setIsPlayDisable,
  setIsPlayerChanges,
  isPlayerChanges,
}) => {
  const [activeBookmark, setActiveBookmark] = useState(false);
  const dispatch = useDispatch();
  const [createBookmarkMutation, { ...createBookmarkMutationProps }] =
    useMutation(CREATE_BOOKMARK_MUTATION);
  const [removeBookmarkMutation, { ...removeBookmarkMutationProps }] =
    useMutation(REMOVE_BOOKMARK_MUTATION);

  const { currentParagraph, bookmark } = useSelector(
    (state) => state.currentParagraphReducer,
  );

  const bookmarks = useSelector((state) => state.bookmarks.bookmarks);
  const soundless = useSelector((state) => state.settings.soundless);
  const bookContent = useSelector((state) => state.bookContent.bookContent);

  const isFirstRunPlay = useRef(true);

  let totalParagraphs = getTotalParagraphs(bookContent);

  let paragraphPosition = totalParagraphs
    .map((item) => item.id)
    .indexOf(currentParagraph.id);

  const pausePlayer = () => {
    const audio = audioRef.current;
    setActivePlayer(false);
    audio.pause();
  };

  const play = () => {
    if (currentTime === audioRef?.current?.duration) {
      setCurrentTime(0);
      setTrackValue(0);
      setInsertsInfo((prevState) => {
        return prevState?.map((item) => ({ ...item, isActive: false }));
      });

      luminator.progress = 0;
    }

    const audio = audioRef.current;
    if (isFirstRunPlay.current && currentParagraph?.bookmark) {
      let myCurrentTime = currentTime;
      audio?.play();
      audio?.pause();
      audio.currentTime = myCurrentTime;
      isFirstRunPlay.current = false;
    }

    if (!activePlayer) {
      setActivePlayer(true);
      audio?.play();
    } else {
      pausePlayer();
    }
  };

  const playInsterts = (insertsInfo, currentTime) => {
    insertsInfo?.forEach((insert) => {
      if (currentTime < insert?.audioStart) {
        const insetIndex = insertsInfo
          .map((item) => +item.id)
          .indexOf(+insert.id);
        insertsInfo[insetIndex].isAudioActive = false;
      }

      if (insert.type === 'AUDIO' || insert.isMuted) {
        if (
          currentTime > insert?.audioStart &&
          currentTime < insert?.audioStart + 0.3 &&
          !insert?.isAudioActive &&
          !isPlayerChanges
        ) {
          pausePlayer();
          setIsPlayDisable(true);
          activePlayer && setActivePlayer(false);
        }
      }
    });
  };

  const hadleChangeBookmark = async () => {
    if (bookmark === null) {
      const result = await createBookmarkMutation({
        variables: {
          paragraphId: +currentParagraph.id,
          time: Math.floor(currentTime),
        },
      });
      if (result?.data) {
        dispatch(createBookmark(20, result?.data?.createBookmark?.id));
        dispatch(
          setBookmarks([
            ...bookmarks,
            {
              ...result?.data?.createBookmark,
              paragraphId: result?.data?.createBookmark?.paragraph?.id,
            },
          ]),
        );
      }
    } else {
      const result = await removeBookmarkMutation({
        variables: {
          id: +bookmark.id,
        },
      });
      if (result?.data) {
        const index = bookmarks.map((item) => item.id).indexOf(bookmark.id);
        let newBookmarksValue = [...bookmarks];
        newBookmarksValue.splice(index, 1);
        dispatch(setBookmarks(newBookmarksValue));
        dispatch(removeBookmark());
      }
    }
    setActiveBookmark(!activeBookmark);
  };

  useEffect(() => {
    playInsterts(insertsInfo, currentTime);
  }, [currentTime]);

  const getCurrentDuration = (e) => {
    const percent =
      (e.currentTarget.currentTime / e.currentTarget.duration) * 100;
    const time = e.currentTarget.currentTime;

    setTrackValue(+percent);
    setCurrentTime(+time);
  };

  useEffect(() => {
    if (
      luminator &&
      currentParagraph?.bookmark?.time &&
      audioRef?.current?.duration
    ) {
      luminator.progress =
        (currentParagraph.bookmark.time / audioRef?.current?.duration) * 100;
    }
  }, [luminator, audioRef?.current?.duration]);

  useEffect(() => {
    const audio = audioRef.current;
    if (currentParagraph.bookmark) {
      setCurrentTime(currentParagraph.bookmark.time);
      setTrackValue(
        (currentParagraph.bookmark.time / audioRef?.current?.duration) * 100,
      );
      audio.currentTime = currentParagraph.bookmark.time;
    } else {
      setCurrentTime(0);
      setTrackValue(0);
    }

    if (isSafari && audio) {
      audio.currentTime = currentTime;
    }
  }, [currentParagraph]);

  useEffect(() => {
    if (luminator) {
      luminator.progress = (currentTime * 100) / audioRef?.current?.duration;
    }
  }, [currentTime]);

  const classes = useStyles({
    playerTrackLineDoneWidth: '30%',
  });

  const handleChange = (event, newValue) => {
    setIsPlayerChanges(true);
    luminator.progress = newValue;

    const audio = audioRef.current;
    audio.currentTime = (audio.duration / 100) * newValue;
    setTrackValue(newValue);

    if (isSafari) {
      if (activePlayer) {
        luminator.start(duration * 1000);
      } else {
        luminator.stop();
      }
    }
  };

  return (
    <Box className={classes.wrapper}>
      <AudioSlider
        value={trackValue}
        min={0}
        step={0.01}
        max={100}
        onChange={handleChange}
        disabled={isPlayDisable}
      />

      {currentParagraph.audio && (
        <audio
          ref={audioRef}
          autoPlay={isSafari ? false : true}
          // eslint-disable-next-line no-undef
          src={`${process.env.REACT_APP_AUDIO_FOLDER_URL}/${currentParagraph.audio}`}
          onLoadedData={() => {
            setDuration(audioRef?.current?.duration);
            const audio = audioRef.current;
            if (audio) {
              audio.volume = soundless === 'ON' ? 1 : 0;
            }
          }}
          onTimeUpdate={getCurrentDuration}
          onPause={() => luminator.stop()}
          onWaiting={() => luminator.stop()}
          onPlaying={() => {
            setActivePlayer(true);
            setIsPlayerChanges(false);
          }}
          onEnded={() => {
            setActivePlayer(false);
            luminator.progress = 100;
          }}
        ></audio>
      )}

      <Box className={classes.playerTimeWrapper}>
        <Typography className={classes.playerTimeText} variant='body2'>
          {getTimeOfBookmark(currentTime)}
        </Typography>
        <Typography className={classes.playerTimeText} variant='body2'>
          {getTimeOfBookmark(duration)}
        </Typography>
      </Box>
      <Box className={classes.playerSwitchesWrapper}>
        {paragraphPosition !== 0 ? (
          <PlayerArrowSwitch
            direction='left'
            paragraphInfo={totalParagraphs[paragraphPosition - 1]}
            onClose={onClose}
          />
        ) : null}

        <Box
          className={classes.playerPlayWrapper}
          style={{
            marginLeft: paragraphPosition === 0 ? 'auto' : 0,
            marginRight:
              paragraphPosition === totalParagraphs.length - 1 ? 'auto' : 0,
          }}
        >
          <Box
            component='button'
            onClick={() => {
              if (!isPlayDisable) {
                play();
              }
            }}
            className={classes.playerPlayBtn}
            style={{
              opacity: isPlayDisable ? 0.4 : 1,
            }}
          >
            {activePlayer ? <PauseIcon /> : <PlayIcon />}
          </Box>
          <Box
            component='button'
            onClick={hadleChangeBookmark}
            className={classes.playerBookmarkBtn}
          >
            {createBookmarkMutationProps.loading ||
            removeBookmarkMutationProps.loading ? (
              <Preloader width={12} height={12} />
            ) : bookmark !== null ? (
              <BookmarksActiveIcon />
            ) : (
              <BookmarksDisabledIcon />
            )}
          </Box>
        </Box>
        {paragraphPosition !== totalParagraphs.length - 1 ? (
          <PlayerArrowSwitch
            direction='right'
            paragraphInfo={totalParagraphs[paragraphPosition + 1]}
            onClose={onClose}
          />
        ) : null}
      </Box>
    </Box>
  );
};

Player.propTypes = {
  luminator: PropTypes.any,
  trackValue: PropTypes.number,
  setTrackValue: PropTypes.func,
  duration: PropTypes.number,
  setDuration: PropTypes.func,
  currentTime: PropTypes.number,
  setCurrentTime: PropTypes.func,
  onClose: PropTypes.func,
  audioRef: PropTypes.object,
  refetchInfo: PropTypes.any,
};
