import { BookContentTypes } from '../types';

const { FETCH_BOOK_CONTENT } = BookContentTypes;

const initialState = {
  bookContent: null,
};

const bookContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BOOK_CONTENT:
      return {
        ...state,
        bookContent: action.bookContent,
      };
    default:
      return state;
  }
};

export default bookContentReducer;
