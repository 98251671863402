export const createContentList = (currentParagraph) => {
  let textValue = currentParagraph?.content;
  const arr = [];
  const createArray = () => {
    currentParagraph?.inserts.map((e) => {
      arr.push({
        start: e?.contentPositionStart,
        end: e?.contentPositionEnd,
        id: e?.id,
        source: e?.source,
      });
    });
    arr.sort((a, b) => (a.start > b.start ? 1 : -1));
  };
  createArray();
  const startValue = ({ id }) => {
    const text = `<span class="insert${id}" id="${id}" style="color:#0B486B ; font-weight: 800; text-decoration: underline; cursor: pointer; ">`;
    return {
      text: text,
      length: text.length,
    };
  };
  let endValue = () => {
    const text = `</span>`;
    return { text: text, length: text.length };
  };
  let shift = 0;
  const createText = (e, shift, key, callback) => {
    return (
      textValue?.slice(0, e[key] + shift) +
      callback(e).text +
      textValue?.slice(e[key] + shift)
    );
  };
  arr.map((e) => {
    textValue = createText(e, shift, 'start', startValue);
    shift = shift + startValue(e).length;
    textValue = createText(e, shift, 'end', endValue);
    shift = shift + endValue(e.id).length;
  });
  return textValue;
};
