import PropTypes from 'prop-types';
import React from 'react';

export const OpenModalIcon = ({
  width = 10,
  height = 10,
  color = '#5C5C5C',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 15 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3 3.83333C3 3.37333 3.37333 3 3.83333 3H5.16667C5.29927 3 5.42645 2.94732 5.52022 2.85355C5.61399 2.75979 5.66667 2.63261 5.66667 2.5C5.66667 2.36739 5.61399 2.24021 5.52022 2.14645C5.42645 2.05268 5.29927 2 5.16667 2H3.83333C3.3471 2 2.88079 2.19315 2.53697 2.53697C2.19315 2.88079 2 3.3471 2 3.83333V5.16667C2 5.29927 2.05268 5.42645 2.14645 5.52022C2.24021 5.61399 2.36739 5.66667 2.5 5.66667C2.63261 5.66667 2.75979 5.61399 2.85355 5.52022C2.94732 5.42645 3 5.29927 3 5.16667V3.83333Z'
        fill={color}
      />
      <path
        d='M3 12.1667C3 12.6267 3.37333 13 3.83333 13H5.16667C5.29927 13 5.42645 13.0527 5.52022 13.1464C5.61399 13.2402 5.66667 13.3674 5.66667 13.5C5.66667 13.6326 5.61399 13.7598 5.52022 13.8536C5.42645 13.9473 5.29927 14 5.16667 14H3.83333C3.3471 14 2.88079 13.8068 2.53697 13.463C2.19315 13.1192 2 12.6529 2 12.1667V10.8333C2 10.7007 2.05268 10.5736 2.14645 10.4798C2.24021 10.386 2.36739 10.3333 2.5 10.3333C2.63261 10.3333 2.75979 10.386 2.85355 10.4798C2.94732 10.5736 3 10.7007 3 10.8333V12.1667Z'
        fill={color}
      />
      <path
        d='M12.1668 3C12.6268 3 13.0002 3.37333 13.0002 3.83333V5.16667C13.0002 5.29927 13.0528 5.42645 13.1466 5.52022C13.2404 5.61399 13.3676 5.66667 13.5002 5.66667C13.6328 5.66667 13.7599 5.61399 13.8537 5.52022C13.9475 5.42645 14.0002 5.29927 14.0002 5.16667V3.83333C14.0002 3.3471 13.807 2.88079 13.4632 2.53697C13.1194 2.19315 12.6531 2 12.1668 2H10.8335C10.7009 2 10.5737 2.05268 10.4799 2.14645C10.3862 2.24021 10.3335 2.36739 10.3335 2.5C10.3335 2.63261 10.3862 2.75979 10.4799 2.85355C10.5737 2.94732 10.7009 3 10.8335 3H12.1668Z'
        fill={color}
      />
      <path
        d='M13.0002 12.1667C13.0002 12.6267 12.6268 13 12.1668 13H10.8335C10.7009 13 10.5737 13.0527 10.4799 13.1464C10.3862 13.2402 10.3335 13.3674 10.3335 13.5C10.3335 13.6326 10.3862 13.7598 10.4799 13.8536C10.5737 13.9473 10.7009 14 10.8335 14H12.1668C12.6531 14 13.1194 13.8068 13.4632 13.463C13.807 13.1192 14.0002 12.6529 14.0002 12.1667V10.8333C14.0002 10.7007 13.9475 10.5736 13.8537 10.4798C13.7599 10.386 13.6328 10.3333 13.5002 10.3333C13.3676 10.3333 13.2404 10.386 13.1466 10.4798C13.0528 10.5736 13.0002 10.7007 13.0002 10.8333V12.1667Z'
        fill={color}
      />
    </svg>
  );
};

OpenModalIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};
