import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import { theme } from '../../../../../constants';
import { setCurrentParagraphId } from '../../../../../redux/actions/currentParagraph';
import { clearSearchValue } from '../../../../../redux/actions/searchParagraphs';
import { ArrowRightIcon } from '../../../../icons';

const useStyles = makeStyles({
  resultSerachWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 16,
    marginBottom: 19,
  },
  countOfElements: {
    fontSize: 12,
    lineHeight: '14px',
    color: theme.colors.white,
  },
  cleanSearch: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  clearSearchText: {
    fontWeight: 500,
    fontSize: 13,
    color: theme.colors.white,
    marginLeft: 9,
  },
  contentWrapper: {
    paddingRight: 16,
    height: 'calc(100vh - 308px)',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4,
      backgroundColor: 'rgba(243, 243, 243, 0.4)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#0B486B',
      borderRadius: 2,
    },
    'scrollbar-color': '#0B486B rgba(243, 243, 243, 0.4)',
    'scrollbar-width': 'thin',
  },
  searchItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
    cursor: 'pointer',
  },
  searchItemId: {
    marginRight: 16,
    fontSize: 15,
    lineHeight: '22px',
    color: theme.colors.white,
  },
  searchItemText: {
    fontSize: 14,
    lineHeight: '16px',
    color: theme.colors.white,
    marginRight: 'auto',
  },
  searchItemTextBold: {
    fontWeight: 700,
  },
});

const SearchContent = ({ onClose }) => {
  const classes = useStyles();
  const searchResult = useSelector(
    (state) => state.searchParagraphs.searchResult,
  );
  const searchText = useSelector((state) =>
    state.searchParagraphs.searchText.toLowerCase(),
  );

  const getShortParagraphContent = (value) => {
    let indexOfSearchValue = value.indexOf(searchText);

    let shortParagraphContent =
      indexOfSearchValue > value.length - 50
        ? value.slice(value.length - 51, value.length - 1)
        : indexOfSearchValue > 18
        ? value.slice(indexOfSearchValue - 18, indexOfSearchValue + 50 - 18)
        : value.slice(0, 50);

    let indexOfShortParagraphContent =
      shortParagraphContent.indexOf(searchText);
    let result = `${shortParagraphContent.slice(
      0,
      indexOfShortParagraphContent,
    )}<b>${searchText}</b>${shortParagraphContent.slice(
      indexOfShortParagraphContent + searchText.length,
      shortParagraphContent.length - 1,
    )}`;

    if (indexOfSearchValue === -1) {
      return ReactHtmlParser(`<b>${searchText}</b>...`);
    }

    return ReactHtmlParser(result);
  };
  const dispatch = useDispatch();

  return (
    <Box>
      <Box className={classes.resultSerachWrapper}>
        <Box
          onClick={() => dispatch(clearSearchValue())}
          className={classes.cleanSearch}
        >
          <Box style={{ transform: 'rotate( 180deg )' }}>
            <ArrowRightIcon />
          </Box>
          <Typography className={classes.clearSearchText}>{'Назад'}</Typography>
        </Box>
        <Typography
          className={classes.countOfElements}
        >{`Всего результатов: ${searchResult?.length}`}</Typography>
      </Box>
      <Box className={classes.contentWrapper} overflow='auto'>
        {searchResult &&
          searchResult?.map((item, index) => (
            <Box
              className={classes.searchItem}
              key={item.id}
              onClick={() => {
                dispatch(setCurrentParagraphId(item.id));
                onClose();
              }}
            >
              <Typography variant='body2' className={classes.searchItemId}>
                {index + 1}
              </Typography>
              <Typography variant='body2' className={classes.searchItemText}>
                {getShortParagraphContent(item.content.toLowerCase())}
              </Typography>
              <ArrowRightIcon />
            </Box>
          ))}
      </Box>
    </Box>
  );
};

SearchContent.propTypes = {
  history: PropTypes.object,
  onClose: PropTypes.func,
};

export default withRouter(SearchContent);
