import PropTypes from 'prop-types';
import React from 'react';

export const ArrowRightIcon = ({ width = 7, height = 10, color = '#fff' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 7 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.70739 9.70715L6.41406 4.99981L1.70739 0.29248L0.292725 1.70715L3.58606 4.99981L0.292725 8.29248L1.70739 9.70715Z'
        fill={color}
      />
    </svg>
  );
};

ArrowRightIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};
