import { useMutation } from '@apollo/client';
import { Box, Switch, withStyles } from '@material-ui/core';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { theme } from '../../../../constants';
import { SET_SETTINGS_MUTATION } from '../../../../graphql';
import {
  fetchSoundState,
  fetchThemeColor,
} from '../../../../redux/actions/settings';
import { SnowFlake, SunIcon } from '../../../icons';
import { Preloader } from '../../../Preloader/Preloader';
import { useStyles } from './styles';

const BlueSwitch = withStyles((t) => ({
  root: {
    width: 38,
    height: 22,
    padding: 0,
    margin: t.spacing(1),
  },
  switchBase: {
    padding: 3,
    '&$checked': {
      transform: 'translateX(16px)',
      color: t.palette.common.white,
      '& + $track': {
        backgroundColor: 'transparent',
        opacity: 1,
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    backgroundColor: theme.colors.blue,
  },
  track: {
    borderRadius: 12,
    border: '1px solid rgba(0, 0, 0, 0.1)',
    backgroundColor: 'white',
    opacity: 1,
  },
  checked: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export const HeaderOptionsMenu = ({ bottom }) => {
  const theme = useSelector((state) => state.settings.theme);
  const soundless = useSelector((state) => state.settings.soundless);

  const dispatch = useDispatch();

  const classes = useStyles({ bottom });

  const headerOptionRef = useRef();

  const [mutation, { loading }] = useMutation(SET_SETTINGS_MUTATION);

  const handleTheme = async () => {
    let newThemeValue = theme === 'COLD' ? 'WARM' : 'COLD';
    const result = await mutation({
      variables: {
        name: 'THEME',
        value: newThemeValue,
      },
    });
    if (result?.data) {
      dispatch(fetchThemeColor(newThemeValue));
    }
  };

  const handleSoundless = async () => {
    let newSoundlessValue = soundless === 'ON' ? 'OFF' : 'ON';
    const result = await mutation({
      variables: {
        name: 'SOUNDLESS',
        value: newSoundlessValue,
      },
    });
    if (result?.data) {
      dispatch(fetchSoundState(newSoundlessValue));
    }
  };

  return (
    <Box ref={headerOptionRef} className={classes.wrapper}>
      <Box className={classes.optionItem}>
        <Box>Режим чтения</Box>
        <Box className={classes.switchWrapper}>
          <Box className={[classes.icon, classes.iconActive].join(' ')}>
            <VolumeOffIcon />
          </Box>
          {loading ? (
            <Box className={classes.preloaderWrapper}>
              <Preloader width={20} height={20} />
            </Box>
          ) : (
            <BlueSwitch
              checked={soundless === 'OFF' ? false : true}
              onChange={handleSoundless}
            />
          )}
          <Box className={classes.icon}>
            <VolumeUpIcon />
          </Box>
        </Box>
      </Box>
      <Box className={classes.optionItem}>
        <Box>Цвет интерфейса</Box>
        <Box className={classes.switchWrapper}>
          <Box className={[classes.icon, classes.iconActive].join(' ')}>
            <SunIcon style={{ opacity: 0.4 }} />
          </Box>
          {loading ? (
            <Box className={classes.preloaderWrapper}>
              <Preloader width={20} height={20} />
            </Box>
          ) : (
            <BlueSwitch
              checked={theme === 'COLD' ? false : true}
              onChange={handleTheme}
            />
          )}
          <Box className={classes.icon}>
            <SnowFlake />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

HeaderOptionsMenu.propTypes = {
  bottom: PropTypes.number.isRequired,
};
