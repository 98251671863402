import { makeStyles } from '@material-ui/core';

import { theme } from '../../../../constants';

export const useStyles = makeStyles({
  wrapper: {
    position: 'absolute',
    width: '322px',
    borderRadius: '6px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.25)',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    background: theme.colors.white,
    top: '53px',
    right: '-22px',
    padding: 16,
    cursor: 'auto',
    zIndex: 3,
  },
  switchWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  optionItem: {
    color: theme.colors.gray,
    fontWeight: 700,
    fontSize: 15,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 4,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  iconActive: {
    opacity: 0.4,
  },
  icon: {
    color: theme.colors.paleBlue,
  },
  preloaderWrapper: {
    position: 'relative',
    width: 38,
    height: 22,
    margin: 8,
  },
});
