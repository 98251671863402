import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import { SettingsIcon } from '../../icons';
import { HeaderOptionsMenu } from './HeaderOptionsMenu/HeaderOptionsMenu';
import { useStyles } from './styles';

export const HeaderOptions = ({ header }) => {
  const classes = useStyles();
  const [showMenu, setShowMenu] = useState(false);
  const HeaderOptionsRef = useRef();

  const clickHandler = () => {
    setShowMenu(!showMenu);
  };

  const offSetBottom =
    header?.current?.offsetHeight +
      HeaderOptionsRef?.current?.offsetHeight +
      7 || 0;

  return (
    <Box ref={HeaderOptionsRef} className={classes.wrapper}>
      <Box onClick={clickHandler}>
        <SettingsIcon />
      </Box>
      {showMenu && <HeaderOptionsMenu bottom={offSetBottom} />}
    </Box>
  );
};

HeaderOptions.propTypes = {
  header: PropTypes.object.isRequired,
};
