import { BookmarksTypes } from '../types';

const { FETCH_BOOKMARKS } = BookmarksTypes;

const initialState = {
  bookmarks: [],
};

const bookmarks = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BOOKMARKS:
      return {
        ...state,
        bookmarks: action.bookmarks,
      };
    default:
      return state;
  }
};

export default bookmarks;
