import PropTypes from 'prop-types';
import React from 'react';

export const PauseIcon = ({ width = 16, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.6369 1.53857H2.47075C1.94311 1.53857 1.51537 1.96631 1.51537 2.49396V13.5063C1.51537 14.0339 1.94311 14.4617 2.47075 14.4617H5.6369C6.16455 14.4617 6.59229 14.0339 6.59229 13.5063V2.49396C6.59229 1.96631 6.16455 1.53857 5.6369 1.53857Z'
        fill='white'
      />
      <path
        d='M5.6369 1.03857H2.47075C1.66696 1.03857 1.01537 1.69017 1.01537 2.49396V13.5063C1.01537 14.3101 1.66696 14.9617 2.47075 14.9617H5.6369C6.44069 14.9617 7.09229 14.3101 7.09229 13.5063V2.49396C7.09229 1.69017 6.44069 1.03857 5.6369 1.03857Z'
        stroke='black'
        strokeOpacity='0.1'
      />
      <path
        d='M13.4831 1.53857H10.3169C9.78927 1.53857 9.36153 1.96631 9.36153 2.49396V13.5063C9.36153 14.0339 9.78927 14.4617 10.3169 14.4617H13.4831C14.0107 14.4617 14.4384 14.0339 14.4384 13.5063V2.49396C14.4384 1.96631 14.0107 1.53857 13.4831 1.53857Z'
        fill='white'
      />
      <path
        d='M13.4831 1.03857H10.3169C9.51312 1.03857 8.86153 1.69017 8.86153 2.49396V13.5063C8.86153 14.3101 9.51312 14.9617 10.3169 14.9617H13.4831C14.2869 14.9617 14.9384 14.3101 14.9384 13.5063V2.49396C14.9384 1.69017 14.2869 1.03857 13.4831 1.03857Z'
        stroke='black'
        strokeOpacity='0.1'
      />
    </svg>
  );
};

PauseIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
