import { CurrentParagraphTypes } from '../types';

const {
  FETCH_CURRENT_PARAGRAPH,
  REMOVE_BOOKMARK,
  CREATE_BOOKMARK,
  SET_CURRENT_PARAGRAPH_ID,
} = CurrentParagraphTypes;

export const fetchCurrentParagraph = (currentParagraph) => ({
  type: FETCH_CURRENT_PARAGRAPH,
  currentParagraph,
});

export const removeBookmark = () => ({
  type: REMOVE_BOOKMARK,
});

export const createBookmark = (time, id) => ({
  type: CREATE_BOOKMARK,
  time,
  id,
});

export const setCurrentParagraphId = (id) => ({
  type: SET_CURRENT_PARAGRAPH_ID,
  id,
});
