import { Box, makeStyles } from '@material-ui/core';
import IconPublish from '@material-ui/icons/Publish';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { theme } from '../../../../constants';
import { ParagraphIcon } from '../../../icons';
import { AsideMenuLabel } from '../AsideMenuLabel';
import { AsideMenuContentsSearch } from './AsideMenuContentsSearch';
import { BookContentMenu } from './BookContentMenu/BookContentMenu';
import SearchContent from './SearchContent/SearchContent';

const useStyles = makeStyles({
  wrapperTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
    justifyContent: 'space-between',
    color: theme.colors.white,
  },
  collapseIcon: {
    transform: 'rotate(-90deg)',
    cursor: 'pointer',
  },
});

export const AsideMenuContents = ({ onClose }) => {
  const classes = useStyles();
  const searchResult = useSelector(
    (state) => state.searchParagraphs.searchResult,
  );
  return (
    <Box>
      <Box className={classes.wrapperTitle}>
        <AsideMenuLabel title={'Оглавление'} icon={<ParagraphIcon />} />
        <IconPublish
          className={classes.collapseIcon}
          onClick={() => onClose()}
        />
      </Box>
      <AsideMenuContentsSearch />
      {searchResult === null ? (
        <BookContentMenu onClose={onClose} />
      ) : (
        <SearchContent onClose={onClose} />
      )}
    </Box>
  );
};

AsideMenuContents.propTypes = {
  onClose: PropTypes.func,
};
