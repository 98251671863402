export const visibleInfoboxes = (currentTime, insertsInfo) => {
  insertsInfo.forEach((insert, index) => {
    if (
      currentTime > insert.audioStart &&
      currentTime < insert.audioStart + 0.3
    ) {
      insertsInfo[index].isActive = true;
      insertsInfo[index].isAudioActive = true;
    }

    if (
      currentTime >= insert.audioEnd - 0.3 &&
      currentTime <= insert.audioEnd + 0.15
    ) {
      insertsInfo[index].isActive = false;
    }

    if (currentTime < insert.audioStart) {
      insertsInfo[index].isActive = false;
    }

    if (insert?.isMuted && currentTime > insert.audioStart + 0.55) {
      insertsInfo[index].isActive = false;
    }
  });
};
