import React from 'react';
import { Route, useLocation } from 'react-router-dom';

import Book from './pages/Book/Book';
import Home from './pages/Home/Home';
import { getToken } from './utils/getToken';

const App = () => {
  const location = useLocation();
  const urlReg = /book/gi;

  const token = getToken();

  const isBookPage = urlReg.test(location?.pathname);

  if (!token || !isBookPage) {
    window.location = process.env.REACT_APP_CATALOG_URL;
  }

  return (
    <>
      <Route path={'/'} render={() => <Home />} exact />
      <Route path={'/book/:id'} render={() => <Book />} exact />
    </>
  );
};

export default App;
