import { makeStyles } from '@material-ui/core';

import { theme } from '../../../constants';

export const useStyles = makeStyles({
  wrapper: {
    padding: '16px 8px 10px',
  },
  playerTrackLineWrapper: {
    marginBottom: 8,
  },
  playerTrackLine: {
    width: '100%',
    backgroundColor: theme.colors.lightBlue,
    height: 6,
    borderRadius: 3,
    cursor: 'pointer',
  },
  playerTrackLineDone: {
    width: (props) => props.playerTrackLineDoneWidth,
    backgroundColor: theme.colors.blue,
    marginTop: '-6px',
  },
  playerTimeWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  playerTimeText: {
    fontSize: 12,
    lineHeight: '14px',
    color: 'rgba(0, 0, 0, 0.4)',
  },
  playerSwitchesWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  playerPlayWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  playerPlayBtn: {
    height: 48,
    width: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.blue,
    border: 'none',
    borderRadius: '50%',
    marginRight: 20,
    cursor: 'pointer',
  },
  playerBookmarkBtn: {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    position: 'relative',
  },
});
