/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import { Box, Typography } from '@material-ui/core';
import lumin from 'lumin';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import { theme } from '../../constants';
import { createContentList } from '../../utils/createContentList';
import { getInsertsFullInfo } from '../../utils/getInsertsFullInfo';
import { markInserts } from '../../utils/markInserts';
import { visibleInfoboxes } from '../../utils/visibleInfoBoxes';
import { InfoBox } from '../InfoBox/InfoBox';
import { ModalInfoBox } from '../ModalInfoBox/ModalInfoBox';
import { Preloader } from '../Preloader/Preloader';
import AudioInsert from './AudioInsert/AudioInsert';
import { Player } from './Player/Player';
import { useStyles } from './styles';

const Content = ({ onClose, loading = false }) => {
  const { currentParagraph } = useSelector(
    (state) => state.currentParagraphReducer,
  );

  const getInsetsInfo =
    currentParagraph &&
    currentParagraph?.inserts
      ?.map((item) => {
        return { ...item, isActive: false };
      })
      .sort((a, b) => {
        return a.contentPositionStart - b.contentPositionStart;
      });

  const [trackValue, setTrackValue] = useState(0);
  const [luminator, setLuminator] = useState(null);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [activePlayer, setActivePlayer] = useState(false);
  const [isPlayDisable, setIsPlayDisable] = useState(false);
  const [insertsInfo, setInsertsInfo] = useState([]);
  const [isPlayerChanges, setIsPlayerChanges] = useState(false);

  const [insertsModalInfo, setInsertsModalInfo] = useState([]);

  const soundless = useSelector((state) => state.settings.soundless);
  const themeState = useSelector((state) => state.settings.theme);
  const contentTextRef = useRef(null);
  const audioRef = useRef(null);

  useEffect(() => {
    setLuminator(lumin(contentTextRef.current));
    if (currentParagraph?.inserts) {
      setInsertsInfo(
        getInsetsInfo.map((item) => ({ ...item, isAudioActive: false })),
      );

      setInsertsModalInfo(getInsetsInfo.map((item) => ({ ...item })));
    }
  }, [currentParagraph?.id]);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.volume = soundless === 'ON' ? 1 : 0;
    }
  }, [soundless]);

  useEffect(() => {
    if (luminator?.progress) {
      luminator.progress = (currentTime / audioRef?.current?.duration) * 100;
    }
    visibleInfoboxes(currentTime, insertsInfo);
  }, [currentTime]);

  useEffect(() => {
    insertsInfo?.forEach((insert) => {
      const id = insert?.id;
      if (id && insert.type !== 'AUDIO') {
        const element = document.getElementById(`${id}`);
        element?.addEventListener('click', visibleInsert);
      }
    });
    return () => {
      insertsInfo?.forEach((insert) => {
        const id = insert?.id;
        if (id && insert.type !== 'AUDIO') {
          const id = insert?.id;
          const element = document.getElementById(`${id}`);
          element?.removeEventListener('click', visibleInsert);
        }
      });
    };
  }, [contentTextRef.current, insertsInfo]);

  const classes = useStyles({
    wrapperBackgroundColor:
      themeState === 'COLD'
        ? theme.colors.background.secondary
        : theme.colors.background.primary,
    backgroundColor:
      themeState === 'COLD'
        ? theme.colors.contentBackground.secondary
        : theme.colors.contentBackground.primary,
    textBackgroundColor: theme.colors.textBackground.primary,
  });

  useEffect(() => {
    markInserts(insertsInfo, currentTime);
  }, [currentTime]);

  if (!audioRef || loading) {
    return <Preloader />;
  }

  const insertsFullInfo = getInsertsFullInfo(currentParagraph?.inserts);

  const visibleInsert = (event) => {
    const insetIndex = insertsFullInfo
      .map((item) => +item.id)
      .indexOf(+event.target.id);
    let newInsertsValue = [...insertsInfo];

    newInsertsValue[insetIndex].isActive = true;
    setInsertsInfo(newInsertsValue);
  };

  return (
    <>
      <Box className={classes.wrapper}>
        <Typography className={classes.title} variant='h3'>
          {currentParagraph?.name}
        </Typography>
        <Box className={classes.contentWrapper}>
          <Box className={classes.content} overflow='auto'>
            <Typography
              ref={contentTextRef}
              className={classes.text}
              dangerouslySetInnerHTML={{
                __html: createContentList(currentParagraph),
              }}
            ></Typography>
          </Box>
        </Box>

        <Player
          luminator={luminator}
          trackValue={trackValue}
          setTrackValue={setTrackValue}
          duration={duration}
          setDuration={setDuration}
          currentTime={currentTime}
          setCurrentTime={setCurrentTime}
          onClose={onClose}
          audioRef={audioRef}
          markInserts={markInserts}
          insertsInfo={insertsInfo}
          activePlayer={activePlayer}
          setActivePlayer={setActivePlayer}
          setInsertsInfo={setInsertsInfo}
          isPlayDisable={isPlayDisable}
          setIsPlayDisable={setIsPlayDisable}
          setIsPlayerChanges={setIsPlayerChanges}
          isPlayerChanges={isPlayerChanges}
        />
      </Box>
      <Box className={classes.infoBoxWrapper} overflow='auto'>
        {insertsInfo.length > 0 &&
          insertsInfo.map((insert, index) => {
            if (insert.type !== 'AUDIO') {
              // if (insert.isActive) {
              return (
                <Box key={index}>
                  <InfoBox
                    title={insert?.name}
                    content={insert?.content}
                    type={insert?.type}
                    source={insert?.source}
                    isMuted={insert?.isMuted}
                    isActive={insert.isActive}
                    audioStart={insert?.audioStart}
                    audioEnd={insert?.audioEnd}
                    onClose={() => {
                      let newInsertsValue = [...insertsInfo];
                      newInsertsValue[index].isActive = false;
                      setInsertsInfo(newInsertsValue);
                    }}
                    onModalOpen={() => {
                      let newInsertsModalValue = [...insertsModalInfo];
                      newInsertsModalValue[index].isActive = true;
                      setInsertsModalInfo(newInsertsModalValue);
                    }}
                    mainAudioRef={audioRef}
                    activePlayer={activePlayer}
                    setActivePlayer={setActivePlayer}
                    setIsPlayDisable={setIsPlayDisable}
                    currentTime={currentTime}
                  />
                </Box>
              );
              // }
            } else {
              return (
                <AudioInsert
                  key={index}
                  insert={insert}
                  currentTime={currentTime}
                  mainAudioRef={audioRef}
                  activePlayer={activePlayer}
                  setActivePlayer={setActivePlayer}
                  insertsInfo={insertsInfo}
                  setInsertsInfo={setInsertsInfo}
                  setIsPlayDisable={setIsPlayDisable}
                  isPlayerChanges={isPlayerChanges}
                />
              );
            }
          })}
      </Box>
      {insertsModalInfo.length > 0 &&
        insertsModalInfo.map((modalInsert, index) => {
          if (modalInsert.isActive) {
            return (
              <Box className={classes.modalWrapper} key={index}>
                <Box
                  className={classes.modalOutside}
                  onClick={() => {
                    let newInsertsModalValue = [...insertsModalInfo];
                    newInsertsModalValue[index].isActive = false;
                    setInsertsModalInfo(newInsertsModalValue);
                  }}
                ></Box>
                <ModalInfoBox
                  title={modalInsert?.name}
                  content={modalInsert?.content}
                  type={modalInsert?.type}
                  source={modalInsert?.source}
                  isMuted={modalInsert?.isMuted}
                  onClose={() => {
                    let newInsertsModalValue = [...insertsModalInfo];
                    newInsertsModalValue[index].isActive = false;
                    setInsertsModalInfo(newInsertsModalValue);
                  }}
                  mainAudioRef={audioRef}
                  activePlayer={activePlayer}
                  setActivePlayer={setActivePlayer}
                  setIsPlayDisable={setIsPlayDisable}
                />
              </Box>
            );
          }
        })}
    </>
  );
};

Content.propTypes = {
  match: PropTypes.object,
  onClose: PropTypes.func,
};

export default withRouter(Content);
