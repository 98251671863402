import { useLazyQuery } from '@apollo/client';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { theme } from '../../../../constants';
import { GET_FIND_PARAGRAPHS_QUERY } from '../../../../graphql/queries';
import {
  fetchSearchParagraphs,
  setSearchValue,
} from '../../../../redux/actions/searchParagraphs';
import { SearchIcon } from '../../../icons';

const useStyles = makeStyles({
  wrapper: {
    position: 'relative',
  },
  input: {
    width: '100%',
    height: 32,
    padding: '0 40px 0 24px',
    color: theme.colors.white,
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: 8,
    border: 'none',
    outline: 'none',
    fontSize: 12,
  },
  icon: {
    position: 'absolute',
    right: 24,
    top: '57%',
    transform: 'translateY(-50%)',
  },
});

export const AsideMenuContentsSearch = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const searchValue = useSelector(
    (state) => state.searchParagraphs.searchValue,
  );
  const classes = useStyles();

  const [searchParagraphQuery, { loading }] = useLazyQuery(
    GET_FIND_PARAGRAPHS_QUERY,
    {
      onCompleted: (response) => {
        dispatch(fetchSearchParagraphs(response?.findParagraphs, searchValue));
      },
    },
  );

  return (
    <Box className={classes.wrapper}>
      <input
        className={classes.input}
        type='text'
        value={searchValue}
        onChange={(e) => dispatch(setSearchValue(e.currentTarget.value))}
      />
      <Box
        className={classes.icon}
        onClick={() => {
          if (searchValue.length > 0) {
            searchParagraphQuery({
              variables: {
                search: searchValue,
                textbookId: +id,
              },
            });
          }
        }}
      >
        {!loading ? <SearchIcon width={16} height={16} /> : null}
      </Box>
    </Box>
  );
};
