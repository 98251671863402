import { MainLayout } from '../../layouts/main/MainLayout';

const Home = () => {
  return (
    <MainLayout>
      <h1>Home page</h1>
    </MainLayout>
  );
};

export default Home;
