import { Box, List } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { BookContentMenuItem } from './BookContentMenuItem';

const useStyles = makeStyles({
  wrapper: {
    padding: '0px 24px 24px 4px',
    marginTop: 24,
    maxHeight: 330,
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4,
      backgroundColor: 'rgba(243, 243, 243, 0.4)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#0B486B',
      borderRadius: 2,
    },
    'scrollbar-color': '#0B486B rgba(243, 243, 243, 0.4)',
    'scrollbar-width': 'thin',
  },
});

export const BookContentMenu = ({ onClose }) => {
  const bookContent = useSelector((state) => state.bookContent.bookContent);

  const classes = useStyles();

  return (
    <Box className={classes.wrapper} overflow='auto'>
      <List component='nav' disablePadding>
        {bookContent?.map((item, index) => (
          <BookContentMenuItem {...item} key={index} onClose={onClose} />
        ))}
      </List>
    </Box>
  );
};

BookContentMenu.propTypes = {
  onClose: PropTypes.func,
};
