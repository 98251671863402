import { makeStyles } from '@material-ui/core';

import { theme } from '../../constants';

export const useStyles = makeStyles({
  header: {
    background: theme.colors.blue,
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
  },
  logoImg: {
    width: 40,
    height: 40,
  },
  logoText: {
    fontWeight: 900,
    color: theme.colors.white,
    marginLeft: 12,
    fontSize: 24,
    lineHeight: 28 / 24,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  container: {
    background: theme.colors.blue,
    paddingTop: 12,
    paddingBottom: 12,
  },
});
