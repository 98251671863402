/* eslint-disable jsx-a11y/media-has-caption */
import { Box, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux';

import { theme } from '../../constants';
import { CloseIcon, CloseModalIcon } from '../icons';

const useStyles = makeStyles({
  infoBoxWrapper: {
    backgroundColor: (props) => props.backgroundColor,
    borderRadius: 8,
    overflow: 'hidden',
    position: 'absolute',
    width: '80vw',
    maxHeight: '80vh',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 3,
    padding: '32px 16px',
  },
  titleWrapper: {
    width: '100%',
    marginBottom: '16px',
  },
  title: {
    fontSize: 18,
    lineHeight: '146%',
    color: theme.colors.black,
    fontWeight: 500,
  },
  img: {
    maxWidth: '90%',
    maxHeight: '50vh',
    margin: '0 auto',
    marginBottom: 16,
    display: 'block',
  },
  video: {
    maxWidth: '90%',
    maxHeight: 600,
    margin: '0 auto',
    marginBottom: 16,
    display: 'block',
  },
  description: {
    color: theme.colors.gray,
    fontSize: 14,
    lineHeight: '146%',
  },
  closeIcon: {
    position: 'absolute',
    top: '12px',
    right: '12px',
    cursor: 'pointer',
  },
  openModalIcon: {
    position: 'absolute',
    bottom: '12px',
    right: '12px',
    cursor: 'pointer',
  },
});

export const ModalInfoBox = ({
  title,
  content,
  type,
  source,
  onClose,
  isMuted,

  mainAudioRef,
  activePlayer,
  setActivePlayer,
  setIsPlayDisable,
}) => {
  const themeState = useSelector((state) => state.settings.theme);

  const classes = useStyles({
    backgroundColor:
      themeState === 'COLD'
        ? theme.colors.contentBackground.secondary
        : theme.colors.contentBackground.primary,
  });

  const setCurrentDuration = () => {
    const mainAudio = mainAudioRef.current;

    !activePlayer && setActivePlayer(true);
    setTimeout(() => {
      mainAudio.play();
    }, 1000);
  };

  return (
    <Box className={classes.infoBoxWrapper}>
      {title ? (
        <Box className={classes.titleWrapper}>
          <Typography variant='h3' className={classes.title}>
            {ReactHtmlParser(title)}
          </Typography>
        </Box>
      ) : null}

      <Box>
        {type === 'VIDEO' ? (
          <video
            className={classes.video}
            // eslint-disable-next-line no-undef
            src={`${process.env.REACT_APP_VIDEO_FOLDER_URL}/${source}`}
            autoPlay={true}
            controls={true}
            muted={!isMuted}
            onEnded={() => {
              if (isMuted) {
                setCurrentDuration();
                setIsPlayDisable(false);
                onClose();
              }
            }}
          ></video>
        ) : type === 'IMAGE' ? (
          // eslint-disable-next-line no-undef
          <img
            src={`${process.env.REACT_APP_IMG_FOLDER_URL}/${source}`}
            alt={title}
            className={classes.img}
          />
        ) : null}
        {content ? (
          <Typography variant='body1' className={classes.description}>
            {ReactHtmlParser(content)}
          </Typography>
        ) : null}
      </Box>
      <Box className={classes.closeIcon} onClick={onClose}>
        <CloseIcon width={10} height={10} color={'#000'} />
      </Box>
      <Box className={classes.openModalIcon} onClick={onClose}>
        <CloseModalIcon width={16} height={16} color={'#000'} />
      </Box>
    </Box>
  );
};

ModalInfoBox.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  type: PropTypes.string,
  source: PropTypes.string,
  onClose: PropTypes.func,
};
