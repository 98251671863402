import { Box } from '@material-ui/core';
import React from 'react';

import { useStyles } from './styles';

export const HeaderMenu = () => {
  const classes = useStyles();

  const nav = ['Библиотека'].map((navItem) => (
    <Box component='li' key={navItem} className={classes.listItem}>
      <Box
        className={classes.listLink}
        onClick={() => {
          window.location = process.env.REACT_APP_CATALOG_URL;
        }}
      >
        {navItem}
      </Box>
    </Box>
  ));

  return (
    <Box component='nav'>
      <Box component='ul'>{nav}</Box>
    </Box>
  );
};
