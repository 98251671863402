import { useMutation } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import { theme } from '../../../../constants';
import { REMOVE_BOOKMARK_MUTATION } from '../../../../graphql';
import { setBookmarks } from '../../../../redux/actions/bookmarks';
import {
  removeBookmark,
  setCurrentParagraphId,
} from '../../../../redux/actions/currentParagraph';
import { getTimeOfBookmark } from '../../../../utils';
import { CloseIcon } from '../../../icons';
import { BookmarkIcon } from '../../../icons/BookmarkIcon';
import { AsideMenuLabel } from '../AsideMenuLabel';

const useStyles = makeStyles({
  bookmarksListWrapper: {
    marginBottom: 22,
    paddingRight: 16,
  },
  bookmarksListItem: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    cursor: 'pointer',
    marginBottom: 11,
    '&:hover': {
      '& span': {
        opacity: 1,
        transition: 'all .5s',
      },
      '& p': {
        '&:nth-child(2)': {
          opacity: 0,
        },
      },
    },
    '& span': {
      position: 'absolute',
      opacity: 0,
      top: 0,
      cursor: 'pointer',
      '&:last-child': {
        right: 0,
      },
    },
    '& p': {
      '&:nth-child(2)': {
        opacity: 1,
      },
    },
  },
  bookmarksListItemTitle: {
    marginRight: 22,
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '21px',
    color: '#D6DDE0',
    marginLeft: 22,
    // eslint-disable-next-line no-dupe-keys
    marginRight: 'auto',
  },
  bookmarksListItemTime: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: `14px`,
    color: theme.colors.white,
  },
  closeIcon: {
    position: 'absolute',
    right: 5,
  },
  closeIconWrapper: {
    position: 'relative',
  },
});

const AsideMenuBookmarks = ({ onClose }) => {
  const classes = useStyles();
  const bookmarks = useSelector((state) => state.bookmarks.bookmarks);
  const bookmark = useSelector(
    (state) => state.currentParagraphReducer.bookmark,
  );

  const [removeBookmarkMutation, { ...removeBookmarkMutationProps }] =
    useMutation(REMOVE_BOOKMARK_MUTATION);
  const dispatch = useDispatch();

  return (
    <Box>
      <AsideMenuLabel title={'Закладки'} icon={<BookmarkIcon />} />
      <Box className={classes.bookmarksListWrapper}>
        {bookmarks.map((item) => (
          <Box className={classes.bookmarksListItem} key={item.id}>
            <Typography
              className={classes.bookmarksListItemTitle}
              onClick={() => {
                dispatch(setCurrentParagraphId(item.paragraphId));
                onClose();
              }}
            >
              {`${item?.paragraph?.name}`}
            </Typography>
            <Typography className={classes.bookmarksListItemTime}>
              {getTimeOfBookmark(item.time)}
            </Typography>
            <Box
              onClick={async () => {
                const result = await removeBookmarkMutation({
                  variables: {
                    id: +item.id,
                  },
                });
                if (result?.data) {
                  const index = bookmarks
                    .map((item) => item.id)
                    .indexOf(item.id);
                  let newBookmarksValue = [...bookmarks];
                  newBookmarksValue.splice(index, 1);
                  dispatch(setBookmarks(newBookmarksValue));
                  if (+item.id === +bookmark?.id) {
                    dispatch(removeBookmark());
                  }
                }
              }}
              className={classes.closeIconWrapper}
              component='span'
            >
              {!removeBookmarkMutationProps.loading ? <CloseIcon /> : null}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

AsideMenuBookmarks.propTypes = {
  history: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

export default withRouter(AsideMenuBookmarks);
