import { List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
// import Divider from '@material-ui/core/Divider'
import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { theme } from '../../../../../constants';
import BookContentMenuItemParagraphs from './BookContentMenuItemParagraphs';

const useStyles = makeStyles({
  menuIcon: {
    color: theme.colors.white,
  },
  playIcon: {
    position: 'absolute',
    left: 0,
  },
  menuItem: {
    padding: 0,
  },
  menuItemText: {
    marginTop: 0,
    marginBottom: 0,
    '& span': {
      margin: 0,
      fontWeight: 500,
      fontSize: 13,
      marginLeft: 40,
      lineHeight: '21px',
      color: '#D6DDE0',
    },
  },
  menuItemChapter: {
    '& span': {
      marginLeft: 20,
    },
  },
});

export const BookContentMenuItem = ({ name, id, onClose, paragraphs }) => {
  const classes = useStyles();

  const currentParagraph = useSelector(
    (state) => state.currentParagraphReducer.currentParagraph,
  );
  const chapterId = id;
  const activeChapterId = currentParagraph?.subject?.chapter?.id;

  const isExpandable = paragraphs && paragraphs.length > 0;
  const [open, setOpen] = React.useState(
    chapterId === activeChapterId ? true : false,
  );

  const handleClick = () => {
    setOpen(!open);
  };

  const MenuItemThemes = isExpandable ? (
    <Collapse in={open} timeout='auto' unmountOnExit>
      <List component='div' disablePadding>
        <BookContentMenuItemParagraphs
          paragraphs={paragraphs}
          onClose={onClose}
        />
      </List>
    </Collapse>
  ) : null;

  return (
    <>
      <ListItem button className={classes.menuItem} onClick={handleClick}>
        <ListItemText
          className={`${classes.menuItemText} ${classes.menuItemChapter}`}
          primary={name}
        />
        {isExpandable && !open && (
          <IconExpandMore className={classes.menuIcon} />
        )}
        {isExpandable && open && (
          <IconExpandLess className={classes.menuIcon} />
        )}
      </ListItem>
      {MenuItemThemes}
    </>
  );
};

BookContentMenuItem.propTypes = {
  name: PropTypes.string,
  subjects: PropTypes.array,
  id: PropTypes.string,
  onClose: PropTypes.func,
};
